import React from "react";
import {connect} from "react-redux";
import "./style.scss";
import "./ac-request-status.scss";
import {
    updateOnePackage,
    updateTiresAdditionalSelected,
    updateTiresSelectedBrandType
} from "../../redux/actions/offerActions";
import {updateOneComment} from "../../redux/actions/offerActions";
import {Translation} from "react-i18next";
import {
    URGENCY_RECOMMENDED,
    URGENCY_SAFETY_RELEVANT,
    URGENCY_URGENT
} from "../../app/consts/requestType";
import {roundCeil, toBool, printPrice, fixedFloat} from "../../app/utils/common";
import MediaGallery from "../MediaGallery/MediaGallery";
import classNames from "classnames";
import {OFFER_CAN_UPDATE} from "../../app/consts/offerStatus";
import ServiceInfo from "../ServiceInfo/ServiceInfo";
import oneTreePlants from '../../assets/images/onetreeplants.png';
import {TIRES_AXELS, TIRES_TYPES} from "../../app/consts/tires";

export const OPENED_STATUS = 1;
export const REJECTED_STATUS = 2;
export const ACCEPTED_STATUS = 3;

// const TYPE_REGULAR = 1 // Unused
const TYPE_OFFICIAL = 2
const TYPE_TIRES = 3

class OfferList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            labourInfo: null,
            oneTreePlants: this.props.offer.tree_plants,
        }
    }

    handleRadioChange = (offer, e, id) => {
        const radioValue = parseInt(e.target.value);
        if (radioValue === REJECTED_STATUS) {
            this.showTextarea(document.getElementById("reject-comment-" + id));
        } else if (radioValue === ACCEPTED_STATUS) {
            this.hideTextarea(document.getElementById("reject-comment-" + id));
        }

        this.props.dispatch(updateOnePackage(offer, e.target.value, id));
        let allRejected = true
        offer.list.forEach(item => {
            if (parseInt(item.user_response) === ACCEPTED_STATUS) {
                allRejected = false;
            }
        })

        this.props.updateAllRejected(allRejected)
    };

    handleTextareaChange = (offer, e, id) => {
        this.props.dispatch(updateOneComment(offer, e.target.value, id));
    };

    hideTextarea = textarea => {
        textarea.classList.remove("show");
        textarea.classList.add("hide");
    };

    showTextarea = textarea => {
        textarea.classList.remove("hide");
        textarea.classList.add("show");
    };

    canShowPackageRadioButtons = () => {
        const {offer} = this.props;

        return OFFER_CAN_UPDATE.indexOf(offer.status) !== -1;
    }

    showInfo = (e, labour) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({labourInfo: labour})
    }

    onClose = () => {
        this.setState({labourInfo: null})
    }

    allowAccept = (item) => {
        if (item.type === TYPE_TIRES) {
            return item.tires.list.find(tireType => tireType.selected);
        }

        return true;
    }

    allowReject = (item) => {
        if (item.type === TYPE_TIRES) {
            return item.tires.list.find(tireType => tireType.selected);
        }

        return true;
    }

    render() {
        const {offer, dispatch} = this.props;
        const {labourInfo} = this.state;

        const typeDetails = {
            [`type-${URGENCY_SAFETY_RELEVANT}`]: {
                key: "urgency_safety_relevant"
                // iconName: 'icon-alert',
            },
            [`type-${URGENCY_URGENT}`]: {
                key: "urgency_urgent"
                // iconName: 'icon-alert',
            },
            [`type-${URGENCY_RECOMMENDED}`]: {
                key: "urgency_recommended"
                // iconName: 'icon-info',
            }
        };

        const customerResponseStatus = {
            [`status-${OPENED_STATUS}`]: {
                key: "open"
            },
            [`status-${REJECTED_STATUS}`]: {
                key: "rejected"
            },
            [`status-${ACCEPTED_STATUS}`]: {
                key: "accepted"
            }
        }

        const mediaContent = media => {
            return <MediaGallery list={media}/>;
        };

        const renderTextArea = (item, submitted) => {
            if (submitted) {
                return null;
            }

            return (
                <Translation>
                    {t => (
                        <textarea
                            id={"reject-comment-" + item.id}
                            className={
                                submitted
                                    ? "show offer-list__textarea"
                                    : "hide offer-list__textarea"
                            }
                            onKeyUp={e => {
                                this.handleTextareaChange(offer, e, item.id);
                            }}
                            disabled={submitted}
                            defaultValue={item.comment}
                            placeholder={t(
                                "components.radio_group_with_comment.textarea_placeholder"
                            )}
                        />
                    )}
                </Translation>
            );
        };

        const renderLabours = item => {
            if (!(item.labours.length > 1)) {
                return null;
            }

            return (
                <div>

                    <div>
                        {item.labours.map(labour => (
                            <div key={labour.id}>
                                <p className="offer-list__labour-item" key={labour.id}>
                                    &#8226; {labour.name}
                                    {(item.type === TYPE_OFFICIAL && Boolean(labour.description)) &&
                                    <div className="offer-list__info">
                                    <span className="link-style" onClick={(e) => this.showInfo(e, labour)}>
                                        <i className="icon-info-fill"/>
                                    </span>
                                    </div>
                                    }
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            );
        };

        const renderCostStatus = (item, submitted, t) => {
            if (!submitted) {
                return null;
            }

            return (
                <div className="AC-Request-Status">
                    <div className="AC-Request-Status__name">
                        <Translation>
                            {() => (
                                <div
                                    className={`AC-Request-Status__${item.status}`}
                                >
                                    <span>{t(`components.add_cost_request.statuses.${customerResponseStatus[`status-${item.status}`].key}`)}</span>
                                </div>
                            )}
                        </Translation>
                    </div>
                </div>
            );
        };

        const renderDiscountPrice = (item, submitted) => {
            if (submitted) {
                return null;
            }

            let appointmentDiscount = offer.appointment_discounts.find(appointment_discount => appointment_discount.selected);

            let itemPrice = () => {

                if (item.type === TYPE_TIRES) {

                    let selectedTire = item.tires.list.find(tireType => tireType.selected);
                    if (selectedTire) {
                        return roundCeil(selectedTire.price_with_tax, true)
                    }

                    return <>{
                        item.tires.list.map(tireType => roundCeil(tireType.price_with_tax, true)).sort((a, b) => a - b).join(' - ')
                    }</>
                }

                return roundCeil(item.price, true)
            }

            return (
                <Translation>
                    {t => (
                        <div>
                            {toBool(item.discount && item.discount.price) && (
                                <p className="offer-list__total-price">
                                    {t("components.add_cost_request.discount", {name: item.discount.name})} - {item.discount.price} {item.currency}
                                </p>
                            )}

                            {(appointmentDiscount && !appointmentDiscount.is_today) ? (
                                <div>
                                    <p className="offer-list__total-price-included">
                                        {t("components.add_cost_request.appointment.total_price_incl_vat_later")}
                                        {toBool(item.discount && item.discount.price) && t("components.add_cost_request.n_discount")}
                                        -
                                        {roundCeil(item.price - item.price * (appointmentDiscount.discount / 100), true)} {item.currency}
                                    </p>
                                </div>
                            ) : (
                                <p className="offer-list__total-price-included">
                                    {t("components.add_cost_request.total_price_incl_vat")}
                                    <span>{toBool(item.discount && item.discount.price) && t("components.add_cost_request.n_discount")}</span>
                                    <span className="pull-r">{itemPrice()} {item.currency}</span>
                                </p>
                            )}
                        </div>
                    )}
                </Translation>
            );
        };

        const renderTireOptions = (item, submitted) => {

            if (submitted) {
                return (
                    <Translation>
                        {t => (
                            <>
                                {item.tires.list.filter(tireType => tireType.selected).map(tireType => (
                                    <>
                                        <div className="offer-list__labour-item" key={tireType.type}>
                                            <div>{tireType.full_name}</div>

                                            {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                                <div className="small" key={axel}>
                                                    {t(`components.add_cost_request.tires.axel.${axel}`)}: {tireType[axel].full_axel}
                                                </div>
                                            ))}
                                        </div>

                                        {item.tires.additional.filter(additional => additional.selected_by_customer).map(additional => (
                                            <div className="offer-list__labour-item" key={additional.item_mp_id}>
                                                <span>{additional.name}</span>
                                            </div>
                                        ))}
                                    </>
                                ))}
                            </>
                        )}
                    </Translation>
                )
            }

            return (
                <Translation>
                    {t => (
                        <>
                            {item.tires.list.length === 1 ?
                                (item.tires.list.map(tireType => (
                                    <div className="tires-pick-item" key={tireType.type}>
                                        {tireType.full_name} <span
                                        className="bold pull-r">{fixedFloat(tireType.price_tires_with_tax)} {item.currency}</span>

                                        {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                            <div className="small" key={axel}>
                                                {t(`components.add_cost_request.tires.axel.${axel}`)}: {tireType[axel].full_axel}
                                            </div>
                                        ))}
                                    </div>
                                )))
                                :
                                (item.tires.list.map(tireType => (
                                    <div className="tires-pick-item" key={tireType.type}>
                                        <div
                                            className="tires-pick-item__title">{t(`components.add_cost_request.tires.brand_type.${tireType.type}`)}</div>

                                        <label className="input-button-container">
                                            <input
                                                className="offer-list__radio-button"
                                                type="radio"
                                                name={`tires-brand-type-${item.id}`}
                                                defaultValue={tireType.type}
                                                value={tireType.type}
                                                onChange={e => dispatch(updateTiresSelectedBrandType(e.target.value, item.id))}
                                            />
                                            <span className="offer-list__custom-radio-button"/>
                                            <div className="offer-list__custom-radio-text">
                                                {tireType.full_name} <span
                                                className="bold pull-r">{fixedFloat(tireType.price_tires_with_tax)} {item.currency}</span>

                                                {TIRES_AXELS.filter(axel => tireType[axel]).map(axel => (
                                                    <div className="small" key={axel}>
                                                        {t(`components.add_cost_request.tires.axel.${axel}`)}: {tireType[axel].full_axel}
                                                    </div>
                                                ))}
                                            </div>
                                        </label>
                                    </div>
                                )))
                            }

                            <div className="mt-30">
                                <p className="input-checkbox">
                                    <label>
                                        <input type="checkbox" name={`tires-mounting`} disabled={true} checked={true}/>
                                        <span className="checkbox-dot"/>
                                        <span>{t("components.add_cost_request.tires.mounting_total_price")} x{item.tires.tires_to_change}</span>
                                        <span
                                            className="bold pull-r">{fixedFloat(item.tires.price_work_with_tax)} {item.currency}</span>
                                    </label>
                                </p>

                                {item.tires.additional.map(additional => {
                                    return (
                                        <p className="input-checkbox" key={additional.item_mp_id}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={`tires-additional-${additional.item_mp_id}`}
                                                    value={additional.item_mp_id}
                                                    checked={additional.selected_by_customer}
                                                    disabled={additional.required}
                                                    onChange={e => dispatch(updateTiresAdditionalSelected(additional.item_mp_id, e.target.checked, item.id))}
                                                />
                                                <span className="checkbox-dot"/>
                                                <span>{additional.name} x{item.tires.tires_to_change}</span>
                                                <span
                                                    className="bold pull-r">{fixedFloat(additional.price_with_tax)} {item.currency}</span>
                                            </label>
                                        </p>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </Translation>
            )

        };

        const renderRadioButtons = (item, submitted) => {
            if (submitted) {
                return null;
            }

            return (
                <Translation>
                    {t => (
                        <div className="offer-list__radio-group">
                            <div className="offer-list__radio-button-container">
                                <label className="input-button-container">
                                    <input
                                        className="offer-list__radio-button"
                                        type="radio"
                                        name={"radio-" + item.id}
                                        defaultValue={ACCEPTED_STATUS}
                                        disabled={submitted || !this.allowAccept(item)}
                                        defaultChecked={submitted && parseInt(item.user_response) === ACCEPTED_STATUS}
                                        onChange={e => this.handleRadioChange(offer, e, item.id)}
                                    />
                                    <span className="offer-list__custom-radio-button"/>
                                    <div className="offer-list__custom-radio-text">
                                        <span
                                            className="offer-list__custom-radio-button__label">{t("components.add_cost_request.accept")}</span>
                                    </div>
                                </label>
                            </div>
                            <div className="offer-list__radio-button-container">
                                <label className="input-button-container">
                                    <input
                                        className="offer-list__radio-button"
                                        type="radio"
                                        name={"radio-" + item.id}
                                        defaultValue={REJECTED_STATUS}
                                        disabled={submitted || !this.allowReject(item)}
                                        defaultChecked={submitted && parseInt(item.user_response) === REJECTED_STATUS}
                                        onChange={e => this.handleRadioChange(offer, e, item.id)}
                                    />
                                    <span className="offer-list__custom-radio-button"/>
                                    <div className="offer-list__custom-radio-text">
                                        <span
                                            className="offer-list__custom-radio-button__label">{t("components.add_cost_request.reject")}</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    )}
                </Translation>
            );
        };

        let iterationItems = offer.list;
        let submitted = false;

        let acceptedPackages = offer.list.filter(item => parseInt(item.user_response) === ACCEPTED_STATUS);
        let acceptedPackagesLength = acceptedPackages.length;
        const plants = this.state.oneTreePlants;

        if (offer.list.length === 0) {
            iterationItems = offer.list_submitted;
            submitted = true;
            acceptedPackagesLength = offer.list_submitted.filter(item => parseInt(item.user_response) === ACCEPTED_STATUS).length;
        }

        let acceptedPrice = 0;

        if (acceptedPackagesLength) {
            acceptedPackages.forEach((item) => {
                if (item.type === TYPE_TIRES) {
                    let selectedTire = item.tires.list.find(tireType => tireType.selected);
                    if (selectedTire) {
                        acceptedPrice += roundCeil(selectedTire.price_with_tax)
                    }
                } else {
                    acceptedPrice += roundCeil(item.price)
                }
            });
        }

        this.props.acceptedPrice(roundCeil(acceptedPrice, true));

        return (
            <Translation>
                {t => (
                    <div className="offer-list">
                        <ul>
                            {iterationItems.map(item => (
                                <li key={item.id}
                                    className={classNames("offer-list__offer-item",
                                        {
                                            "offer-list__offer-item-active": item.user_response !== null && item.user_response !== OPENED_STATUS && !submitted
                                        }
                                    )}
                                >
                                    <h4>{item.title}</h4>

                                    {renderLabours(item)}

                                    <div>
                                        <span
                                            className={`offer-list__offer-type offer-list__type-color-${item.urgency}`}
                                        >
                                            {t(
                                                `components.add_cost_request_type.${typeDetails[`type-${item.urgency}`].key}`
                                            )}
                                        </span>
                                    </div>

                                    {item.description && (
                                        <div>
                                            <p className="offer-list__description">
                                                {item.description}
                                            </p>
                                        </div>
                                    )}

                                    {mediaContent(item.media)}

                                    {item.type === TYPE_TIRES && renderTireOptions(item, submitted)}

                                    {renderDiscountPrice(item, submitted)}

                                    {submitted && (
                                        <p className="offer-list__total-price-included">
                                            {t(
                                                "components.add_cost_request.total_price"
                                            )}{" "}

                                            {printPrice(item.price, item.currency)}

                                        </p>
                                    )}

                                    {this.canShowPackageRadioButtons(item) &&
                                    <div>
                                        {renderRadioButtons(item, submitted)}

                                        {renderTextArea(item, submitted)}
                                    </div>
                                    }

                                    {renderCostStatus(item, submitted, t)}

                                </li>
                            ))}
                        </ul>
                        {plants &&
                        <div className="offer-list__offer-item">
                            <p>
                                <strong>
                                    {acceptedPackagesLength} {' '} {t("components.plants.title")}
                                </strong>
                            </p>
                            <p>
                                {plants.text}
                            </p>
                            <div className="one-tree-plant-detail">
                                <img src={oneTreePlants} alt="One Tree Plants"/>
                                <p>{t("components.plants.details")} <br/>
                                    <a href="https://onetreeplanted.org">onetreeplanted.org</a></p>
                            </div>

                            <p><em>{t('components.plants.total')} {plants.tree_number}</em></p>
                        </div>
                        }
                        {labourInfo !== null &&
                        <ServiceInfo
                            content={
                                <ul>
                                    <li><span>{labourInfo.name}:</span> {labourInfo.description}</li>
                                </ul>
                            }
                            onClose={() => this.setState({labourInfo: null})}
                        />
                        }
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items,
    loading: state.offer.loading,
    error: state.offer.error
});

export default connect(mapStateToProps)(OfferList);
