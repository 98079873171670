import axios from "axios";
import { LOCAL_STORAGE_TOKEN } from "../consts/localStorage";
import { getLocale } from "../utils/common";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
    headers: {
        Language: getLocale()
    }
});

instance.interceptors.request.use(config => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

    if (token) {
        config.headers.common.token = token;
    }

    return config;
});

export default instance;
