import React from "react";
import { connect } from "react-redux";
import "./style.css";
import { Translation } from "react-i18next";

class GarageDetails extends React.Component {
    render() {
        const { offer } = this.props;

        return (
            <Translation>
                {t => (
                    <div className="garage-details">
                        <p className="garage-details__name">
                            {offer.garage.name}
                        </p>
                        <p className="garage-details__address">
                            {offer.garage.address}
                        </p>
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items,
    loading: state.offer.loading,
    error: state.offer.error
});

export default connect(mapStateToProps)(GarageDetails);
