import React from "react";
import "./style.scss";
import {Translation} from "react-i18next";
import {google, outlook, office365, yahoo, ics} from "calendar-link";
import moment from "moment";
import i18n from "i18next";
import {connect} from "react-redux";

class EventCalendar extends React.Component {
    render() {
        const {offer} = this.props;

        let appointmentSelected = offer.appointment_discounts.find(
            appointment => appointment.selected
        );

        let startTime = "";
        let endTime = "";

        if (appointmentSelected) {
            startTime = moment(
                appointmentSelected.datetime,
                "DD.MM.YYYY hh:mm"
            ).toDate();
            endTime = moment(startTime).add(1, "hours");
        }

        const eventData = {
            start: startTime,
            end: endTime,
            duration: [1, "day"],
            title:
                i18n.t("pages.thank_you.calendar_title") +
                " " +
                offer.garage.name,
            description: "",
            location: "",
            busy: true
        };

        return (
            <div>
                {appointmentSelected && !appointmentSelected.is_today && !appointmentSelected.is_today_selected && (
                    <Translation>
                        {t => (
                            <div className="event_calendar">
                                <span className="event_calendar__title">
                                    {t("pages.thank_you.add_to_calendar")}
                                </span>
                                <br/>
                                <a href={google(eventData)} rel="noopener noreferrer" target="_blank">
                                    Google Calendar
                                </a>
                                <br/>
                                <a href={outlook(eventData)} rel="noopener noreferrer" target="_blank">
                                    Outlook Calendar
                                </a>
                                <br/>
                                <a href={yahoo(eventData)} rel="noopener noreferrer" target="_blank">
                                    Yahoo Calendar
                                </a>
                                <br/>
                                <a href={office365(eventData)} rel="noopener noreferrer" target="_blank">
                                    Office365 Calendar
                                </a>
                                <br/>
                                <a href={ics(eventData)} rel="noopener noreferrer" target="_blank">
                                    Apple iCalendar
                                </a>
                                <br/>
                            </div>
                        )}
                    </Translation>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items
});

export default connect(mapStateToProps)(EventCalendar);
