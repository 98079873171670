import './app/config/polyfills'

import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/app.scss";
import OfferPage from "./components/OfferPage/OfferPage";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers/rootReducer";
import {I18nextProvider} from "react-i18next";
import i18n from "./app/config/i18n";
import "./index.css";

const store = createStore(rootReducer, applyMiddleware(thunk));

function App() {
    return (
        <div className="App">
            <OfferPage/>
        </div>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <App/>
        </Provider>
    </I18nextProvider>,
    rootElement
);
