import React from "react";
import "./style.scss";
import { Translation } from "react-i18next";

class ServiceInfo extends React.Component {
    render() {

        const { content, onClose } = this.props

        return (
            <Translation>
                {t => (
                    <>
                        <div className="service-info-popup">
                            <div className="service-info-overlay">
                                <div className="popup call-popup">
                                    <div>
                                        <div className="service-info__header__subtitle">
                                            { content }
                                        </div>

                                        <div className="service-info__close_button_container">
                                            <button
                                                className={`btn btn-lg btn-primary btn-confirm`}
                                                onClick={onClose}>
                                                {t(
                                                    "components.service_info.close"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Translation>
        );
    }
}

export default ServiceInfo;
