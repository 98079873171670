import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR, TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY} from "../../consts/tires";

export default {
    translations: {
        global: {
            loading: "Chargé",
            back: "Retour",
            header: {
                title: "Offre supplémentaire"
            }
        },
        pages: {
            add_cost_review: {
                slug: "/",
                description:
                    "Bonjour {{name}}, Nous avons vérifié votre véhicule et déterminé qu'il a besoin de travaux supplémentaires.",
                we_recommend: "Nous recommandons les réparations suivantes",
                send_further_request: "Confirmer",
                manager_info: "Nous contacter",
                token_invalid: "Le jeton n'est pas valide",
                time_due:
                    "Nous avons besoin de votre réponse jusqu'à {{time}} afin que votre véhicule puisse être terminé à la date convenue.",
                files: {
                    see_file: "Cliquez ici pour ouvrir le fichier"
                },
                read_more: "Plus",
                read_less: "Moins",
                error: "Un problème est survenu. Veuillez recharger la page."
            },
            thank_you: {
                slug: "/thank-you",
                title: "Merci beaucoup",
                message:
                    "Merci pour votre réponse. Nous traiterons votre demande dans les plus brefs délais.",
                add_to_calendar: "Ajouter au calendrier",
                calendar_title: "Rendez-vous à"
            }
        },
        components: {
            service_info: {
                close: 'Fermer',
            },
            offer_page: {
                pick_appointment_date_label: "Sélectionnez une date de rendez-vous",
                pick_appointment_date: "Choisissez une date",
                today: "Aujourd'hui",
                pick_appointment_date_label_is_today: "ou choisissez une date ultérieure.",
                is_today_select: "Choisissez votre date pour les travaux supplémentaires"
            },
            add_cost_request: {
                total_price: "Total",
                discount: "Rabais {{name}}",
                total_price_incl_vat: "Total TTC",
                appointment: {
                    total_price_incl_vat_today: "Total actuel TVA incluse",
                    total_price_incl_vat_later: "Total avec rabais et TVA.",

                    today: "Aujourd'hui",
                    choose_date:
                        "Choisissez votre date pour les travaux supplémentaires",
                    get_discount:
                        "ou plus tard et bénéficiez d'une réduction de {{discount}}%",
                    choose_appointment:
                        "Choisissez votre date pour les travaux supplémentaires et bénéficiez d'une réduction de {{discount}}%.",

                    submitted: {
                        appointment: "Date de rendez-vous: {{appointment}}"
                    }
                },
                n_discount: " et rabais",
                accept: "Accepter",
                reject: "Refuser",
                statuses: {
                    open: 'Ouvert',
                    accepted: 'Approuvé',
                    rejected: 'Rejeté',
                },
                tires: {
                    brand_type: {
                        [TIRES_TYPE_BRAND_PREMIUM]: 'Option Premium',
                        [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Option Qualité/Prix',
                    },
                    axel: {
                        [TIRES_AXEL_FRONT]: 'Avant',
                        [TIRES_AXEL_REAR]: 'Arrière',
                    },
                    mounting_total_price: 'Montage des pneus (y compris l\'équilibrage)',
                    disposal_total_price: 'Élimination des pneus',
                }
            },
            add_cost_request_type: {
                urgency_safety_relevant: "SÉCURITÉ PERTINENTE",
                urgency_urgent: "IMPORTANT",
                urgency_recommended: "CONSEILLÉ"
            },
            manager_detail: {
                title: "Informations de contact",
                manager: "Conseiller Service",
                email: "E-mail: ",
                phone: "Numéro de téléphone: "
            },
            processed: {
                processed: "traité"
            },
            call_me_popup: {
                validation_message: "Le numéro de téléphone est requis",
                call_you: "Demander un rappel",
                decline: "Annuler",
                confirm: "Confirmer",
                title: "Demande d'appel",
                content: "Votre numéro de téléphone",
                success: "La demande d'appel a été envoyée au conseiller service"
            },
            radio_group_with_comment: {
                textarea_placeholder: "Raison du rejet"
            },

            plants: {
                title: 'Plantation d\'un arbre', // TODO Translate
                details: 'Plus d\'informations.', // TODO Translate
                total: 'Total plants:', // TODO Translate
            }
        }
    }
};
