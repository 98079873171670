export const STATUS_PENDING_USER = 3
export const STATUS_RESPONDED_USER = 4
export const STATUS_ARCHIVED = 6

export const OFFER_CAN_UPDATE = [
    STATUS_PENDING_USER,
]

export const OFFER_SHOW_CALL_ME_POPUP = [
    STATUS_PENDING_USER,
    STATUS_RESPONDED_USER,
    STATUS_ARCHIVED,
]

export default {
    STATUS_PENDING_USER,
    STATUS_RESPONDED_USER,
    STATUS_ARCHIVED,
    OFFER_CAN_UPDATE,
}

