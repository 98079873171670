import React, { Component } from "react";

class FileAudio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: props.audio,
            audio: new Audio(
                props.audio.fileObject
                    ? window.URL.createObjectURL(props.audio.fileObject)
                    : props.audio.name
            ),
            play: false
        };
    }

    componentDidMount() {
        this.state.audio.addEventListener("ended", () =>
            this.setState({ play: false })
        );
    }

    componentWillUnmount() {
        this.state.audio.removeEventListener("ended", () =>
            this.setState({ play: false })
        );
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play
                ? this.state.audio.play()
                : this.state.audio.pause();
        });
    };

    render() {
        return (
            <React.Fragment>
                <i
                    className={`${
                        this.state.play ? "icon-pause" : "icon-audio"
                    }`}
                    onClick={this.togglePlay}
                />
            </React.Fragment>
        );
    }
}

export default FileAudio;
