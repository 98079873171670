import React from "react";
import {Translation} from "react-i18next";

const Loading = () => {
    return (
        <Translation>
            {t => (
                <div className="offer-list__loading-init-page">
                    <div className="offer-list__submitted-box-loading-init">
                        <div className="spinner">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                </div>
            )}
        </Translation>
    );
}


export default (Loading);
