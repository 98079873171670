import React, { Component } from "react";
import "./Gallery.scss";
import imgPdf from "../../assets/images/pdf.png";
import imgVideo from "../../assets/images/video.jpg";
import Lightbox from "react-image-lightbox";
import { ext } from "../../app/utils/common";
import { Translation } from "react-i18next";
import FileAudio from "./Audio/FileAudio";

const ALLOWED_IMAGE_TYPES = ["png", "jpeg", "jpg"];
const ALLOWED_VIDEO_TYPES = [];
export const ALLOWED_AUDIO_TYPES = [
    "m4a",
    "mp3",
    "wav",
    "aac",
    "flac",
    "wmv",
    "mpeg",
    "mpga",
    "webm",
    "oga",
    "mp4"
];

const ALLOWED_FILE_TYPES = ["pdf"];

export class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files:
                props.files && props.files.length
                    ? props.files.map(file => {
                          return {
                              id: file.id,
                              name: file.name
                          };
                      })
                    : [],
            photoIndex: null
        };
    }

    renderFile = (file, index) => {
        let item,
            path = file.name;

        if (ALLOWED_IMAGE_TYPES.indexOf(ext(file.name)) !== -1) {
            item = (
                <img
                    src={path}
                    alt={file.name}
                    onClick={() => this.setState({ photoIndex: index })}
                />
            );
        } else if (ALLOWED_VIDEO_TYPES.indexOf(ext(file.name)) !== -1) {
            item = <img src={imgVideo} alt={file.name} />;
        } else if (ALLOWED_FILE_TYPES.indexOf(ext(file.name)) !== -1) {
            item = <img src={imgPdf} alt={file.name} />;
        } else if (ALLOWED_AUDIO_TYPES.indexOf(ext(file.name)) !== -1) {
            item = (
                <div className="file-upload-item__audio">
                    <FileAudio audio={file} />
                </div>
            );
        }

        return (
            <div key={file.id} className="file-upload-item">
                {ALLOWED_FILE_TYPES.indexOf(ext(file.name)) !== -1 && (
                    <a
                        download={file.name}
                        href={path}
                        className="file-upload-item__action file-upload-item__download"
                    >
                        <i className="icon-file-upload" />
                    </a>
                )}
                {item}
            </div>
        );
    };

    render() {
        const { photoIndex } = this.state;

        let images = this.state.files
            .filter(f => ALLOWED_IMAGE_TYPES.indexOf(ext(f.name)) !== -1)
            .map(f => f.name);

        return (
            <Translation>
                {t => (
                    <>
                        <div>
                            <div className="package-files-vertical float-none">
                                {this.state.files.length && (
                                    <div className="files-upload-list">
                                        {this.state.files.map((file, index) =>
                                            this.renderFile(file, index)
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        {photoIndex !== null && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={
                                    images[(photoIndex + 1) % images.length]
                                }
                                prevSrc={
                                    images[
                                        (photoIndex + images.length - 1) %
                                            images.length
                                    ]
                                }
                                onCloseRequest={() =>
                                    this.setState({ photoIndex: null })
                                }
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex:
                                            (photoIndex + images.length - 1) %
                                            images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex:
                                            (photoIndex + 1) % images.length
                                    })
                                }
                            />
                        )}
                    </>
                )}
            </Translation>
        );
    }
}

export default Gallery;
