import i18n from "i18next";
import resources from "../lang";
import { getLocale } from "../utils/common";

i18n.init({
    resources,
    lng: getLocale(),
    fallbackLng: process.env.REACT_APP_FALLBACK_LANG,
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        formatSeparator: ","
    },
    react: {
        wait: true
    }
});

export default i18n;
