import React from "react";
import "./style.scss";
import axios from "../../app/config/axios";
import { Translation } from "react-i18next";
import { urlSearchParams } from "../../app/utils/common";
import {OFFER_SHOW_CALL_ME_POPUP} from "../../app/consts/offerStatus";

class CallMePopup extends React.Component {
    render() {
        if (this.props.reached_max_calls) {
            return null;
        }

        if (!this.props.customer) {
            return null;
        }

        let phone = this.props.customer.phone;

        const hideCallPopup = callPopup => {
            if (callPopup) {
                callPopup.classList.remove("show");
                callPopup.classList.add("hide");
            }
        };

        const showCallMeRequestSuccess = () => {
            document
                .getElementById("call-me-success")
                .classList.add("call-me-success-show");
        };

        const hideCallMeButton = () => {
            document.getElementById("call-me-popup").remove();
        };

        const setPhone = (phoneElement, validationElement) => {
            phone = phoneElement.value;
            if (phone === "") {
                if (validationElement) {
                    validationElement.innerText =
                        validationElement.dataset.message;
                }
                return false;
            } else {
                if (validationElement) {
                    validationElement.innerText = "";
                }
                return true;
            }
        };

        const showCallPopup = callPopup => {
            if (callPopup) {
                callPopup.classList.remove("hide");
                callPopup.classList.add("show");
            }
        };

        const handleCallMePopup = callPopup => {
            if (callPopup) {
                showCallPopup(callPopup);
            }
        };

        const handleConfirm = callPopup => {
            if (
                !setPhone(
                    document.getElementById("input-phone-number"),
                    document.getElementById("validation-message")
                )
            )
                return;

            if (callPopup) {
                axios
                    .post("/call-me", {
                        customer_token: urlSearchParams("token"),
                        phone_number: phone
                    })
                    .then(res => {
                        hideCallPopup(callPopup);
                        hideCallMeButton();
                        if (res.data.message === "success") {
                            showCallMeRequestSuccess();
                        }
                    })
                    .catch(error => {
                        hideCallPopup(callPopup);
                    });
            }
        };

        const handleDecline = callPopup => {
            if (callPopup) {
                callPopup.classList.remove("show");
                callPopup.classList.add("hide");
            }
        };

        return (
            <Translation>
                {t => (
                    <>
                        <div id="call-me-success" className="call-me-success">
                            {t("components.call_me_popup.success")}
                        </div>
                        <div className="call-me-popup" id="call-me-popup">
                            <div id="call-popup" className="popup-overlay hide">
                                <div className="popup call-popup">
                                    <div className="popup__header">
                                        <span className="popup__header__title">
                                            {t(
                                                "components.call_me_popup.title"
                                            )}
                                        </span>
                                        <div className="popup__header__subtitle">
                                            {t(
                                                "components.call_me_popup.content"
                                            )}
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control input-phone-number"
                                            id="input-phone-number"
                                            defaultValue={phone}
                                            onChange={event =>
                                                setPhone(
                                                    event.target,
                                                    document.getElementById(
                                                        "validation-message"
                                                    )
                                                )
                                            }
                                            placeholder="+xx xxx xxx"
                                        ></input>
                                        <div
                                            className="validation-message"
                                            id="validation-message"
                                            data-message={t(
                                                "components.call_me_popup.validation_message"
                                            )}
                                        ></div>
                                        <button
                                            className={`btn btn-lg btn-primary btn-confirm`}
                                            onClick={() =>
                                                handleConfirm(
                                                    document.getElementById(
                                                        "call-popup"
                                                    )
                                                )
                                            }
                                        >
                                            {t(
                                                "components.call_me_popup.confirm"
                                            )}
                                        </button>
                                        <button
                                            className={`btn btn-lg btn-secondary btn-decline`}
                                            onClick={() =>
                                                handleDecline(
                                                    document.getElementById(
                                                        "call-popup"
                                                    )
                                                )
                                            }
                                        >
                                            {t(
                                                "components.call_me_popup.decline"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {OFFER_SHOW_CALL_ME_POPUP.indexOf(this.props.offer.status) !== -1 &&
                                <button
                                    className="btn"
                                    onClick={() =>
                                        handleCallMePopup(
                                            document.getElementById("call-popup")
                                        )
                                    }
                                >
                                    {t("components.call_me_popup.call_you")}
                                </button>
                            }
                        </div>
                    </>
                )}
            </Translation>
        );
    }
}

export default CallMePopup;
