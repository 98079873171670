import "./style.scss";
import React from "react";
import {connect} from "react-redux";
import {fetchOffer} from "../../redux/actions/offerActions";
import OfferList, {ACCEPTED_STATUS} from "../OfferList/OfferList";
import ManagerDetails from "../ManagerDetails/ManagerDetails";
import GarageDetails from "../GarageDetails/GarageDetails";
import SubmitButton from "../SubmitButton/SubmitButton";
import OfferHeader from "../OfferHeader/OfferHeader";
import {Translation} from "react-i18next";
import CallMePopup from "../CallMePopup/CallMePopup";
import {urlSearchParams} from "../../app/utils/common";
import {Helmet} from "react-helmet";
import "./loader.css";
import carIcon from "../../assets/images/car_icon.svg";
import AppointmentDiscounts from "../AppointmentDiscounts/AppointmentDiscounts";
import EventCalendar from "../EventCalendar";
import {OFFER_CAN_UPDATE} from "../../app/consts/offerStatus";
import Submitting from "./parts/Submitting";
import Loading from "./parts/Loading";
import Errored from "./parts/Errored";

const COMPONENTS_APPOINTMENT_DISCOUNTS = "appointment_discounts";
// const GARAGE_APPOINTMENT = 1 // Unused
const USER_APPOINTMENT = 2

const DAY_SATURDAY = 6
const DAY_SUNDAY = 0

class OfferPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invalid: null,
            allRejected: false,
            totalPrice: 0,
        };

        this[COMPONENTS_APPOINTMENT_DISCOUNTS] = React.createRef();
        this.updateAllRejected = this.updateAllRejected.bind(this);
    }

    updateAllRejected = status => {
        this.setState({
            allRejected: status
        })
    }

    componentDidMount() {
        if (this.tokenExist()) {
            this.props.dispatch(fetchOffer(urlSearchParams("token")));
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {offer} = nextProps;

        if (offer.list) {
            if (
                this.requestsProcessed(offer) === this.requestsToProcess(offer)
            ) {
                this.areComponentsValid(offer);
            }
        }
    }

    tokenExist = () => {
        return (
            urlSearchParams("token") !== "undefined" &&
            urlSearchParams("token") != null
        );
    };

    requestsProcessed = offer => {
        return offer.list.filter(request => request.user_response !== null && request.user_response !== 1).length;
    };

    requestsToProcess = offer => {
        return offer && offer.list && offer.list.length;
    };

    areComponentsValid = offer => {
        if (
            offer.appointment_discounts.length > 0 &&
            !offer.appointment_discounts.find(
                appointment => appointment.selected
            ) &&
            offer.list.filter(request => parseInt(request.user_response) === ACCEPTED_STATUS).length
        ) {
            this.setState({invalid: COMPONENTS_APPOINTMENT_DISCOUNTS});
        } else {
            this.setState({invalid: null});
        }
    };

    setDefaultTime = (waitingTime, date = new Date()) => {
        for (let i = 0; i <= waitingTime; i++) {
            date.setDate(date.getDate() + 1);
            let dayOfWeek = date.getDay();

            if (dayOfWeek === DAY_SATURDAY || dayOfWeek === DAY_SUNDAY) {
                return this.setDefaultTime(waitingTime, date);
            }

            return this.setDefaultTime(--waitingTime, date);
        }

        return date;
    }


    isAppointmentSelectedByUser = offer => {
        let appointmentSelected = offer.appointment_discounts.find(appointment => appointment.selected)

        return appointmentSelected && appointmentSelected.type === USER_APPOINTMENT;
    }

    isAllRejected = offer => {
        return offer.appointment_discounts.length === 0 && offer.list_submitted.length > 0;
    }

    acceptedPriceUpdated = price => {
        this.setState({totalPrice: price});
    }

    render() {
        const {error, loading, offer, submitted, submitting} = this.props;

        const goBack = () => {
            window.location.reload();
        };

        if (!this.tokenExist()) {
            return (
                <Translation>
                    {t => (
                        <div className="offer-list__loading">
                            <div className="offer-list__submitted-box">
                                <span>
                                    {t("pages.add_cost_review.token_invalid")}
                                </span>
                            </div>
                        </div>
                    )}
                </Translation>
            );
        }

        if (submitted) {
            let appointmentSelected = offer.appointment_discounts.find(
                appointment => appointment.selected
            );

            let thankYouPageClass = "offer-list__submitted-box";

            if (appointmentSelected && !appointmentSelected.is_today) {
                thankYouPageClass = "offer-list__submitted-box-at-top";
            }

            return (
                <Translation>
                    {t => (
                        <div>
                            <div className="offer-list__loading">
                                <div className={thankYouPageClass}>
                                    <img src={carIcon} className="offer-list__car-icon" alt="Car icon"/>
                                    <br/>
                                    <span className="offer-list__offer-span-title">
                                        {t("pages.thank_you.title")}
                                    </span>
                                    <br/>
                                    <span className="offer-list__offer-span-message">
                                        {t("pages.thank_you.message")}
                                    </span>
                                    <br/>

                                    <EventCalendar/>
                                </div>
                            </div>
                            <div className="container-submitted">
                                <a
                                    onClick={goBack}
                                    className="submit-button__button-back"
                                >
                                    {t("global.back")}
                                </a>
                            </div>
                        </div>
                    )}
                </Translation>
            );
        }

        if (error) {
            return (<Errored/>);
        } else if (submitting) {
            return (<Submitting/>)
        } else if (loading) {
            return (<Loading/>);
        }

        if (offer.list) {
            let requestsProcessed = this.requestsProcessed(offer);
            let requestsToProcess = this.requestsToProcess(offer);

            return (
                <Translation>
                    {t => (
                        <div>
                            <Helmet>
                                <title>
                                    {t("global.header.title")} -{" "}
                                    {offer.car.make} {offer.car.range}
                                </title>
                            </Helmet>

                            <div className="container">
                                <OfferHeader/>
                                <div ref={ref => (this[COMPONENTS_APPOINTMENT_DISCOUNTS] = ref)}>
                                    <AppointmentDiscounts
                                        error={this.state.invalid === COMPONENTS_APPOINTMENT_DISCOUNTS}/>
                                </div>

                                <OfferList updateAllRejected={this.updateAllRejected}
                                           acceptedPrice={this.acceptedPriceUpdated}/>

                                <ManagerDetails/>
                                <CallMePopup
                                    offer={offer}
                                    customer={offer.customer}
                                    reached_max_calls={offer.reached_max_calls}
                                />
                                <GarageDetails/>
                            </div>
                            {OFFER_CAN_UPDATE.indexOf(offer.status) !== -1 &&
                            <SubmitButton
                                requestsProcessed={requestsProcessed}
                                requestsToProcess={requestsToProcess}
                                isAllRejected={this.state.allRejected}
                                areComponentsValid={this.state.invalid}
                                totalPrice={this.state.totalPrice}
                                scrollToError={component => {
                                    if (this[component].scrollIntoView) {
                                        this[component].scrollIntoView();
                                    }
                                }}
                            />
                            }
                        </div>
                    )}
                </Translation>
            );
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        offer: state.offer.items,
        loading: state.offer.loading,
        error: state.offer.error,
        submitted: state.offer.submitted,
        submitting: state.offer.submitting
    };
};

export default connect(mapStateToProps)(OfferPage);
