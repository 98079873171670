import React from "react";
import { connect } from "react-redux";
import "./style.css";
import imgAvatar from "../../assets/images/avatar.jpeg";
import { Translation } from "react-i18next";

class ManagerDetails extends React.Component {
    render() {
        const { offer } = this.props;

        return (
            <Translation>
                {t => (
                    <div className="manager-details" id="manager-details">
                        <h4 className="manager-details__h4">
                            {t("components.manager_detail.title")}
                        </h4>
                        <div className="manager-details__container">
                            <div className="manager-details__avatar">
                                <img
                                    className="manager-details__img"
                                    src={
                                        offer.manager.avatar
                                            ? offer.manager.avatar
                                            : imgAvatar
                                    }
                                    alt={
                                        offer.manager.first_name +
                                        " " +
                                        offer.manager.last_name
                                    }
                                />
                            </div>
                            <div className="manager-details__account">
                                <p className="manager-details__name">
                                    {offer.garage.signature}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items,
    loading: state.offer.loading,
    error: state.offer.error
});

export default connect(mapStateToProps)(ManagerDetails);
