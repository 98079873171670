function getOffer(customerToken) {
    return fetch(process.env.REACT_APP_BASE_URL_API + "get-offer", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            customer_token: customerToken
        })
    })
        .then(handleErrors)
        .then(res => res.json());
}

function updateOffer(offer, customerToken) {
    return fetch(process.env.REACT_APP_BASE_URL_API + "update", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            list: offer.list,
            appointment_discount: offer.appointment_discounts.find(
                appointment => appointment.selected
            )
                ? offer.appointment_discounts.find(
                    appointment => appointment.selected
                ).id
                : null,
            customer_token: customerToken
        })
    })
        .then(handleErrors)
        .then(res => {
            return res.json();
        });
}

export function submitFeedback(offer, customerToken) {
    return dispatch => {
        dispatch(submitFeedbackBegin());
        return updateOffer(offer, customerToken)
            .then(json => {
                dispatch(submitFeedbackFinished());
                return json;
            })
            .catch(error => dispatch(fetchOfferFailure(error)));
    };
}

export function fetchOffer(customerToken) {
    return dispatch => {
        dispatch(fetchOfferBegin());
        return getOffer(customerToken)
            .then(json => {
                dispatch(fetchOfferSuccess(json));
                return json;
            })
            .catch(error => dispatch(fetchOfferFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const FETCH_OFFER_BEGIN = "FETCH_OFFER_BEGIN";
export const FETCH_OFFER_SUCCESS = "FETCH_OFFER_SUCCESS";
export const FETCH_OFFER_FAILURE = "FETCH_OFFER_FAILURE";
export const UPDATE_ONE_PACKAGE = "UPDATE_ONE_PACKAGE";
export const UPDATE_ONE_COMMENT = "UPDATE_ONE_COMMENT";
export const UPDATE_APPOINTMENT_DISCOUNT = "UPDATE_APPOINTMENT_DISCOUNT";
export const SUBMIT_FEEDBACK_BEGIN = "SUBMIT_FEEDBACK_BEGIN";
export const SUBMIT_FEEDBACK_FINISHED = "SUBMIT_FEEDBACK_FINISHED";
export const UPDATE_TIRES_SELECTED_BRAND_TYPE = "UPDATE_TIRES_SELECTED_BRAND_TYPE";
export const UPDATE_TIRES_ADDITIONAL_SELECTED = "UPDATE_TIRES_ADDITIONAL_SELECTED";

export const fetchOfferBegin = () => ({
    type: FETCH_OFFER_BEGIN
});

export const fetchOfferSuccess = offer => ({
    type: FETCH_OFFER_SUCCESS,
    payload: {offer}
});

export const fetchOfferFailure = error => ({
    type: FETCH_OFFER_FAILURE,
    payload: {error}
});

export const updateOnePackage = (offer, userResponse, id) => ({
    type: UPDATE_ONE_PACKAGE,
    payload: {
        offer: offer,
        user_response: userResponse,
        id: id
    }
});

export const updateOneComment = (offer, comment, id) => ({
    type: UPDATE_ONE_COMMENT,
    payload: {
        offer: offer,
        comment: comment,
        id: id
    }
});

export const submitFeedbackFinished = () => ({
    type: SUBMIT_FEEDBACK_FINISHED
});

export const submitFeedbackBegin = () => ({
    type: SUBMIT_FEEDBACK_BEGIN
});

export const updateAppointmentDiscount = (selected, id) => ({
    type: UPDATE_APPOINTMENT_DISCOUNT,
    payload: {
        selected: selected,
        id: id
    }
});

export const updateTiresSelectedBrandType = (brandType, id) => ({
    type: UPDATE_TIRES_SELECTED_BRAND_TYPE,
    payload: {
        brandType, id
    }
});

export const updateTiresAdditionalSelected = (itemMpId, checked, id) => ({
    type: UPDATE_TIRES_ADDITIONAL_SELECTED,
    payload: {
        itemMpId, checked, id
    }
});
