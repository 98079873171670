import React from "react";
import {connect} from "react-redux";
import "./style.scss";
import {Translation} from "react-i18next";
import ShowMoreText from "react-show-more-text";

class OfferHeader extends React.Component {
    managerDetailId = "manager-details";

    handleReadMore = e => {
        document
            .getElementById("garage-comment")
            .classList.add("header__remove-max-height");
        e.target.remove();
    };

    render() {
        const {offer} = this.props;

        return (
            <Translation>
                {t => (
                    <div className="header">
                        <div className="header__title">
                            <h1>{t("global.header.title")}</h1>
                            <h3>
                                {offer.car.make} {offer.car.range}
                            </h3>
                        </div>
                        <div className="header__description">
                            <p>
                                {t("pages.add_cost_review.description", {
                                    name: `${offer.customer.first_name} ${offer.customer.last_name}`
                                })}
                            </p>

                            {offer.time_due && (
                                <p>
                                    {t("pages.add_cost_review.time_due", {
                                        time: offer.time_due
                                    })}
                                </p>
                            )}

                            {offer.comment && (
                                <div className="header__garage-comment">
                                    <ShowMoreText
                                        lines={1}
                                        more={t("pages.add_cost_review.read_more")}
                                        less={t("pages.add_cost_review.read_less")}
                                        anchorClass=""
                                        expanded={false}
                                        width={420}
                                        keepNewLines={true}
                                    >
                                        {offer.comment}
                                    </ShowMoreText>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items,
    loading: state.offer.loading,
    error: state.offer.error
});

export default connect(mapStateToProps)(OfferHeader);
