export const urlSearchParams = (name, href) => {
    if (!href) {
        href = window.location.href;
    }

    let url = new URL(href);
    let params = new URLSearchParams(url.search.slice(1));

    return params.get(name);
};

export const ext = name => {
    return name.split(".").pop();
};

export const roundCeil = (number, forPrint = false) => {
    let float = parseFloat(
        (Math.ceil(parseFloat(number) * 20 - 0.5) / 20).toFixed(2)
    );

    if (forPrint) {
        return float.toFixed(2)
    }

    return float
};

export const fixedFloat = number => {
    return number.toFixed(2)
}

export const getLocale = () => {
    return urlSearchParams("lang") ? urlSearchParams("lang") : window.language;
};

export const toBool = val => !!val;

export const printPrice = (price, currency, discount = null) => {
    let result

    if (discount !== null && discount.discount !== 0) {

        result = roundCeil(price - price * (discount.discount / 100), true)

    } else {

        result = roundCeil(price, true)

    }

    return `${result} ${currency}`
}
