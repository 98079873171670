import React from "react";
import {connect} from "react-redux";
import "./style.css";
import {Translation} from "react-i18next";
import {submitFeedback} from "../../redux/actions/offerActions";
import {urlSearchParams} from "../../app/utils/common";

class SubmitButton extends React.Component {
    handleSubmit = (offer) => {
        const {areComponentsValid, scrollToError} = this.props;

        if (areComponentsValid === null) {
            this.props.dispatch(
                submitFeedback(offer, urlSearchParams("token"))
            );
        } else {
            scrollToError(areComponentsValid);
        }
    };

    isDisabled = (requestsProcessed, requestsToProcess) => {
        return requestsProcessed !== requestsToProcess;
    }

    render() {
        const {offer, requestsProcessed, requestsToProcess, totalPrice} = this.props;

        if (offer.list.length === 0) {
            return null;
        }

        const currency = offer.list[0].currency;
        return (
            <Translation>
                {t => (
                    <div className="submit-button__container">
                        <div className="container">
                            <button
                                onClick={() => {
                                    this.handleSubmit(offer);
                                }}
                                className="submit-button__button"
                                disabled={this.isDisabled(requestsProcessed, requestsToProcess)}
                            >
                                {t(
                                    "pages.add_cost_review.send_further_request"
                                )}{" "}
                                {requestsProcessed}/{requestsToProcess}
                                {" "}
                                {totalPrice !== 0 && (
                                    <span>- {t('components.add_cost_request.total_price')}: {" "}
                                        {totalPrice} {currency}
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items,
    loading: state.offer.loading,
    error: state.offer.error
});

export default connect(mapStateToProps)(SubmitButton);
