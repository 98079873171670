import React from "react";
import { connect } from "react-redux";
import "./style.scss";
import { Translation } from "react-i18next";
import { updateAppointmentDiscount } from "../../redux/actions/offerActions";
import EventCalendar from "../EventCalendar";

class AppointmentDiscounts extends React.Component {
    componentDidMount() {
        const { offer } = this.props;

        if (offer.appointment_discounts.length === 1) {
            this.props.dispatch(updateAppointmentDiscount(true, 0));
        }
    }

    renderAppointmentItem = (item, key) => {
        return (
            <Translation key={key}>
                {t => (
                    <div className="appointment-discount-item">
                        <label className="appointment-discount__label-container">
                            <input
                                className="appointment-discount__radio-button"
                                type="radio"
                                name="appointment_discount"
                                value={item.datetime}
                                checked={item.selected}
                                onChange={e =>
                                    this.props.dispatch(
                                        updateAppointmentDiscount(true, key)
                                    )
                                }
                            />
                            <span className="appointment-discount__custom-radio-button" />
                            {item.is_today
                                ? t(
                                      "components.add_cost_request.appointment.today"
                                  )
                                : item.datetime}
                        </label>
                    </div>
                )}
            </Translation>
        );
    };

    render() {
        const { offer, error } = this.props;

        let submitted = offer.list_submitted.length > 0;

        if (offer.appointment_discounts.length === 0) {
            return null;
        }

        let appointmentSelected = offer.appointment_discounts.find(
            appointment => appointment.selected
        );

        if (submitted && (!appointmentSelected || appointmentSelected.is_today)) {
            return null;
        }

        if (submitted && appointmentSelected) {
            return (
                <Translation>
                    {t => (
                        <div className="appointment-discounts">
                            <div className="small">
                                {t(
                                    "components.add_cost_request.appointment.submitted.appointment",
                                    {
                                        appointment:
                                            (appointmentSelected.is_today_selected) ? appointmentSelected.datetime.split(' ')[0] : appointmentSelected.datetime
                                    }
                                )}
                            </div>

                            <EventCalendar />
                        </div>
                    )}
                </Translation>
            );
        }

        let appointmentToday = offer.appointment_discounts.find(
            appointment_discount => appointment_discount.is_today
        );
        let appointmentsLater = offer.appointment_discounts.filter(
            appointment_discount => !appointment_discount.is_today
        );

        if (appointmentsLater.length === 0) {
            return null;
        }

        return (
            <Translation>
                {t => (
                    <div
                        className={`appointment-discounts ${
                            error ? "appointment-discounts--error" : ""
                        }`}
                    >
                        {appointmentsLater[0].discount === 0 ? (
                            <div>
                                <span>
                                    {t(
                                        "components.add_cost_request.appointment.choose_date"
                                    )}{" "}
                                </span>
                                {appointmentToday ? (
                                    <div>
                                        {this.renderAppointmentItem(
                                            appointmentToday,
                                            offer.appointment_discounts.findIndex(
                                                appointment_discount =>
                                                    appointment_discount.is_today
                                            )
                                        )}
                                        {appointmentsLater.map(
                                            (appointment, key) => {
                                                return this.renderAppointmentItem(
                                                    appointment,
                                                    key + 1
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    <div className="appointment-discount-item">
                                        {appointmentsLater.map(
                                            (appointment, key) => {
                                                return this.renderAppointmentItem(
                                                    appointment,
                                                    key
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {appointmentToday ? (
                                    <div>
                                        <span>
                                            {t(
                                                "components.add_cost_request.appointment.choose_date"
                                            )}{" "}
                                        </span>
                                        {this.renderAppointmentItem(
                                            appointmentToday,
                                            offer.appointment_discounts.findIndex(
                                                appointment_discount =>
                                                    appointment_discount.is_today
                                            )
                                        )}

                                        <span>
                                            {t(
                                                "components.add_cost_request.appointment.get_discount",
                                                {
                                                    discount:
                                                        appointmentsLater[0]
                                                            .discount
                                                }
                                            )}
                                        </span>
                                        {appointmentsLater.map(
                                            (appointment, key) => {
                                                return this.renderAppointmentItem(
                                                    appointment,
                                                    key + 1
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    <div className="appointment-discount-item">
                                        <span>
                                            {t(
                                                "components.add_cost_request.appointment.choose_appointment",
                                                {
                                                    discount:
                                                        appointmentsLater[0]
                                                            .discount
                                                }
                                            )}
                                        </span>
                                        {appointmentsLater.map(
                                            (appointment, key) => {
                                                return this.renderAppointmentItem(
                                                    appointment,
                                                    key
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

const mapStateToProps = state => ({
    offer: state.offer.items
});

export default connect(mapStateToProps)(AppointmentDiscounts);
