if (!Array.prototype.first) {
    Array.prototype.first = function() {
        if (typeof this !== 'object') {
            return undefined;
        }

        return this[0];
    }
}


