import {
    FETCH_OFFER_BEGIN,
    FETCH_OFFER_SUCCESS,
    FETCH_OFFER_FAILURE,
    UPDATE_ONE_PACKAGE,
    UPDATE_ONE_COMMENT,
    SUBMIT_FEEDBACK_FINISHED,
    SUBMIT_FEEDBACK_BEGIN,
    UPDATE_APPOINTMENT_DISCOUNT,
    UPDATE_TIRES_SELECTED_BRAND_TYPE,
    UPDATE_TIRES_ADDITIONAL_SELECTED,
} from "../actions/offerActions";
import {toBool} from "../../app/utils/common";
import {TIRES_TYPES} from "../../app/consts/tires";

const initialState = {
    items: [],
    loading: false,
    error: null,
    submitted: null,
    submitting: null
};

export default function offerReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_OFFER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SUBMIT_FEEDBACK_FINISHED:
            return {
                ...state,
                submitted: true
            };

        case SUBMIT_FEEDBACK_BEGIN:
            return {
                ...state,
                submitting: true
            };

        case FETCH_OFFER_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.offer
            };

        case FETCH_OFFER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        case UPDATE_ONE_COMMENT:
            return {
                ...state,
                loading: false,
                items: {
                    car: {
                        ...state.items.car
                    },
                    customer: {
                        ...state.items.customer
                    },
                    garage: {
                        ...state.items.garage
                    },
                    list: [
                        ...state.items.list.map(request => {
                            if (request.id === action.payload.id) {
                                request.comment = action.payload.comment;
                                return request;
                            }

                            return request;
                        })
                    ],
                    list_submitted: [...state.items.list_submitted],
                    manager: {
                        ...state.items.manager
                    },
                    time_due: action.payload.offer.time_due,
                    comment: action.payload.offer.comment,
                    reached_max_calls: action.payload.offer.reached_max_calls,
                    status: action.payload.offer.status,
                    appointment_discounts: [
                        ...state.items.appointment_discounts
                    ]
                }
            };

        case UPDATE_ONE_PACKAGE:
            return {
                ...state,
                loading: false,
                items: {
                    car: {
                        ...state.items.car
                    },
                    customer: {
                        ...state.items.customer
                    },
                    garage: {
                        ...state.items.garage
                    },
                    list: [
                        ...state.items.list.map(request => {
                            if (request.id === action.payload.id) {
                                request.user_response =
                                    action.payload.user_response;
                                return request;
                            }

                            return request;
                        })
                    ],
                    list_submitted: [...state.items.list_submitted],
                    manager: {
                        ...state.items.manager
                    },
                    time_due: action.payload.offer.time_due,
                    comment: action.payload.offer.comment,
                    reached_max_calls: action.payload.offer.reached_max_calls,
                    status: action.payload.offer.status,
                    appointment_discounts: [
                        ...state.items.appointment_discounts
                    ]
                }
            };

        case UPDATE_APPOINTMENT_DISCOUNT:
            return {
                ...state,
                loading: false,
                items: {
                    ...state.items,
                    appointment_discounts: [
                        ...state.items.appointment_discounts.map(
                            (discount, key) => {
                                discount.selected = key === action.payload.id;
                                return discount;
                            }
                        )
                    ]
                }
            };

        case UPDATE_TIRES_SELECTED_BRAND_TYPE:
            return {
                ...state,
                items: {
                    ...state.items,
                    ...state.items.list.map(item => {
                        if (item.id === action.payload.id) {
                            item.tires.list = item.tires.list.map(tireType => {
                                tireType.selected = toBool(action.payload.brandType === tireType.type)

                                return tireType;
                            })
                        }

                        return item;
                    })
                }
            };

        case UPDATE_TIRES_ADDITIONAL_SELECTED:

            let sumTiresTotalPrice = (item, tireType) => {
                let price = tireType.price_tires_with_tax;
                price += item.tires.price_work_with_tax;

                for (let additional of item.tires.additional) {
                    if(additional.selected_by_customer){
                        price += additional.price_with_tax;
                    }
                }

                return price;
            }

            return {
                ...state,
                items: {
                    ...state.items,
                    ...state.items.list.map(item => {
                        if (item.id === action.payload.id) {
                            item.tires.additional = item.tires.additional.map(additional => {
                                if(additional.item_mp_id === action.payload.itemMpId){
                                    additional.selected_by_customer = action.payload.checked
                                }

                                return additional;
                            })

                            item.tires.list.map(tireType => {
                                tireType.price_with_tax = sumTiresTotalPrice(item, tireType);

                                return tireType;
                            })
                        }

                        return item;
                    })
                }
            };

        default:
            return state;
    }
}
