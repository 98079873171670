import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR, TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY} from "../../consts/tires";

export default {
    translations: {
        global: {
            loading: "Laden",
            back: "Zurück",
            header: {
                title: "Zusatzofferte"
            }
        },
        pages: {
            add_cost_review: {
                slug: "/",
                description:
                    "Hallo {{name}}, wir haben Ihr Fahrzeug überprüft und festgestellt, dass zusätzliche Arbeiten erforderlich sind.",
                we_recommend: "Wir empfehlen folgende Reparaturen",
                send_further_request: "Bestätigen",
                manager_info: "Kontaktieren Sie uns",
                token_invalid: "Token ist ungültig",
                time_due:
                    "Damit Ihr Fahrzeug zum vereinbarten Termin fertiggestellt werden kann, benötigen wir Ihre Antwort bis {{time}} Uhr.",
                files: {
                    see_file: "Klicken Sie hier, um die Datei zu öffnen"
                },
                read_more: "Mehr",
                read_less: "Weniger",
                error: "Something went wrong. Please reload the page."
            },
            thank_you: {
                slug: "/thank-you",
                title: "Vielen Dank",
                message:
                    "Vielen Dank für Ihre Antwort. Wir werden Ihre Anfrage so schnell wie möglich bearbeiten.",
                add_to_calendar: "Zum Kalender hinzufügen",
                calendar_title: "Termin in"
            }
        },
        components: {
            service_info: {
                close: 'Schließen',
            },
            offer_page: {
                pick_appointment_date_label: "Wählen Sie einen Termin aus",
                pick_appointment_date: "Wählen Sie ein Datum",
                today: "Heute",
                pick_appointment_date_label_is_today: "oder wählen Sie einen späteren Termin.",
                is_today_select: "Wählen Sie Ihren Termin für die Zusatzarbeiten"
            },
            add_cost_request: {
                total_price: "Total",
                discount: "Rabatt {{name}}",
                total_price_incl_vat: "Total inkl MwSt",
                appointment: {
                    total_price_incl_vat_today: "Total jetzt inkl MwSt",
                    total_price_incl_vat_later: "Total inkl. Rabatt und MwSt.",

                    today: "Heute",
                    choose_date:
                        "Wählen Sie Ihren Termin für die Zusatzarbeiten",
                    get_discount:
                        "oder später und erhalten Sie {{discount}}% Rabatt",
                    choose_appointment:
                        "Wählen Sie Ihren Termin für die Zusatzarbeiten und erhalten Sie {{discount}}% Rabatt.",

                    submitted: {
                        appointment: "Termin: {{appointment}}"
                    }
                },
                n_discount: " und Rabatt",
                accept: "Akzeptieren",
                reject: "Ablehnen",
                statuses: {
                    open: 'Geöffnet',
                    accepted: 'Akzeptiert',
                    rejected: 'Abgelehnt',
                },
                tires: {
                    brand_type: {
                        [TIRES_TYPE_BRAND_PREMIUM]: 'Premium-Option',
                        [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Qualität/Preis-Option',
                    },
                    axel: {
                        [TIRES_AXEL_FRONT]: 'Vorne',
                        [TIRES_AXEL_REAR]: 'Hinten',
                    },
                    mounting_total_price: 'Reifen montieren (inkl. Auswuchten)',
                    disposal_total_price: 'Reifenentsorgung',
                }
            },
            add_cost_request_type: {
                urgency_safety_relevant: "SICHERHEITSRELEVANT",
                urgency_urgent: "WICHTIG",
                urgency_recommended: "EMPFOHLEN"
            },
            manager_detail: {
                title: "Kontaktinfos",
                manager: "Manager",
                email: "E-Mail: ",
                phone: "Telefonnnummer: "
            },
            processed: {
                processed: "verarbeitet"
            },
            call_me_popup: {
                validation_message: "Telefonnummer ist erforderlich",
                call_you: "Rückruf anfordern",
                decline: "Abbrechen",
                confirm: "Bestätigen",
                title: "Anrufanfrag",
                content: "Ihre Telefonnnummer",
                success: "Anrufanfrage wurde an die KDB gesendet"
            },
            radio_group_with_comment: {
                textarea_placeholder: "Grund der Ablehnung"
            },

            plants: {
                title: 'Plantation d\'un arbre', // TODO Translate
                details: 'Plus d\'informations.', // TODO Translate
                total: 'Total plants:', // TODO Translate
            }
        }
    }
};
