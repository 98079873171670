import {TIRES_AXEL_FRONT, TIRES_AXEL_REAR, TIRES_TYPE_BRAND_PREMIUM, TIRES_TYPE_BRAND_PRICE_QUALITY} from "../../consts/tires";

export default {
    translations: {
        global: {
            loading: "Loading",
            back: "Back",
            header: {
                title: "Additional work request"
            }
        },
        pages: {
            add_cost_review: {
                slug: "/",
                description:
                    "Hello {{name}}, we’ve checked your vehicle and found out that additional work is needed.",
                we_recommend: "We recommend the following repairs",
                send_further_request: "Confirm",
                manager_info: "See manager info",
                token_invalid: "Token is invalid",
                time_due:
                    "Please reply until {{time}}, that we could order the parts and finish the car today.",
                files: {
                    see_file: "Click here to open file"
                },
                read_more: "Read more",
                read_less: "Read less",
                error: "Something went wrong. Please reload the page."
            },
            thank_you: {
                slug: "/thank-you",
                title: "Thank you",
                message:
                    "Dear customer, thank you for your reply. We will process your request as soon as possible.",
                add_to_calendar: "Add to my calendar",
                calendar_title: "Appointment at"
            }
        },
        components: {
            service_info: {
                close: 'Close',
            },
            offer_page: {
                pick_appointment_date_label: "Select one appointment date" ,
                pick_appointment_date: "Choose a date",
                today: "Today",
                pick_appointment_date_label_is_today: "or choose a later date.",
                is_today_select: "Choose your date for the additional work"
            },
            add_cost_request: {
                total_price: "Total",
                discount: "Discount {{name}}",
                total_price_incl_vat: "Total incl VAT",
                appointment: {
                    total_price_incl_vat_today: "Total today incl VAT",
                    total_price_incl_vat_later: "Total later incl VAT",
                    today: "Today",
                    choose_date: "Choose your date for the additional work",
                    get_discount: "or later and get a {{discount}}% discount.",
                    choose_appointment:
                        "Choose your date for the additional work and receive a {{discount}}% discount.",
                    submitted: {
                        appointment: "Appointment: {{appointment}}"
                    }
                },
                n_discount: " and Discount",
                accept: "Accept",
                reject: "Reject",
                statuses: {
                    open: 'Open',
                    accepted: 'Accepted',
                    rejected: 'Rejected',
                },
                tires: {
                    brand_type: {
                        [TIRES_TYPE_BRAND_PREMIUM]: 'Premium option',
                        [TIRES_TYPE_BRAND_PRICE_QUALITY]: 'Quality/Price option',
                    },
                    axel: {
                        [TIRES_AXEL_FRONT]: 'Front',
                        [TIRES_AXEL_REAR]: 'Rear',
                    },
                    mounting_total_price: 'Mounting tires (incl balancing)',
                    disposal_total_price: 'Tire disposal',
                }
            },
            add_cost_request_type: {
                urgency_safety_relevant: "SECURITY-RELATED",
                urgency_urgent: "IMPORTANT",
                urgency_recommended: "RECOMMENDED"
            },
            manager_detail: {
                title: "Garage manager details",
                manager: "Manager",
                email: "Email",
                phone: "Phone"
            },
            processed: {
                processed: "processed"
            },
            call_me_popup: {
                validation_message: "Phone number is required",
                call_you: "Let Customer Service Advisor call you",
                decline: "Decline",
                confirm: "Confirm",
                title: "Call request",
                content: "Your phone number",
                success: "Call request has been sent to the KDB"
            },
            radio_group_with_comment: {
                textarea_placeholder: "Reason of rejection"
            },

            plants: {
                title: 'Plantation d\'un arbre', // TODO Translate
                details: 'Plus d\'informations.', // TODO Translate
                total: 'Total plants:', // TODO Translate
            }
        }
    }
};
