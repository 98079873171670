import React from "react";
import {Translation} from "react-i18next";

const Errored = () => {
    return (
        <Translation>
            {t => (
                <div className="offer-list__loading">
                    <div className="offer-list__submitted-box-error">
                        <span>{t("pages.add_cost_review.error")}</span>
                    </div>
                </div>
            )}
        </Translation>
    );
}


export default (Errored);
