import React from "react";
import "./index.scss";
import { Gallery } from "../Gallery/Gallery";

class MediaGallery extends React.Component {
    render() {
        if (this.props.list && this.props.list.length === 0) {
            return null;
        }

        return (
            <div>
                <div className="Media-Gallery">
                    <Gallery files={this.props.list} />
                </div>
            </div>
        );
    }
}

export default MediaGallery;
